<template>
  <article class="home-page" :style="{ backgroundImage: 'url(' + image + ')' }">
    <hgroup class="page-title">
      <h1 class="title-text">Hunters Point Hemp</h1>
    </hgroup>
    <section class="page-content photo-credit">
      <a href="https://unsplash.com/photos/b2haCjfk_cM?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink">
        Kimzy Nanney | Unsplash
      </a>
    </section>
  </article>
</template>

<script>

export default {
  name: 'HomeView',
  data () {
    return {
      image: `${window.location.href}banner--hempoil.jpg`
    }
  }
}
</script>
<style lang="scss">
  .home-page { 
    background-size: cover;
    background-position: center;
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
    height: 100vh;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .page-title {

      .title-text {
        color: #D2B48C;
        font-weight: bold;
        font-size: 2em;
      }
    }

    .page-content {

      &.photo-credit {
        font-size: 0.8em;
        position: absolute;
        bottom: 0;

        a {
          color: grey;

          &:hover {
            color: gainsboro;
          }
        }
      }

    }
  }
</style>
